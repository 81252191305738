'use client'

import { useEffect } from 'react'
import { Button, Dialog, Navigation, BottomSheet } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'
import type { CloseTarget } from '@vinted/web-ui/dist/types/constants/portal'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import useTranslate from 'hooks/useTranslate'
import useBreakpoint from 'hooks/useBreakpoint'
import useAuthModal from '_libs/common/auth-modal/useAuthModal'

import Auth from '../Auth'
import useAuthTracking from '../hooks/useAuthTracking'
import useAuthenticationContext from '../hooks/useAuthenticationContext'
import { AuthenticationProvider } from '../containers/AuthenticationProvider'
import { ComponentLocation } from '../observability/constants'
import AuthenticationErrorBoundary from '../AuthenticationErrorBoundary'

export const AuthModal = () => {
  const translate = useTranslate('auth')
  const breakpoints = useBreakpoint()
  const { trackClickEvent } = useAuthTracking()
  const { resetAuthView } = useAuthenticationContext()
  const { isAuthModalOpen, closeAuthModal } = useAuthModal()
  const isBottomSheetDisabled = useFeatureSwitch('disable_auth_modal_bottom_sheet')

  useEffect(() => {
    if (!isAuthModalOpen) return

    resetAuthView()
  }, [resetAuthView, isAuthModalOpen])

  const handleButtonClose = () => {
    trackClickEvent({ target: ClickableElement.CloseSelectTypeModal, targetDetails: 'button' })

    closeAuthModal()
  }

  const handleModalClose = (closeTarget: CloseTarget) => {
    trackClickEvent({
      target: ClickableElement.CloseSelectTypeModal,
      targetDetails: closeTarget === Dialog.CloseTarget.CloseIcon ? 'button' : 'overlay',
    })

    closeAuthModal()
  }

  if (!isAuthModalOpen) return null

  if (breakpoints.phones && !isBottomSheetDisabled) {
    return (
      <BottomSheet
        isVisible
        onClose={handleModalClose}
        closeButtonEnabled
        initialHeight={BottomSheet.Height.FullHeight}
        testId="auth-bottom-sheet"
      >
        <div className="u-ui-padding-top-x-large">
          <Auth />
        </div>
      </BottomSheet>
    )
  }

  return (
    <Dialog
      testId="auth-modal"
      show
      defaultCallback={handleModalClose}
      closeOnOverlay
      hasScrollableContent
      className="auth__container"
      aria={{
        labelledby: 'auth_modal_title',
      }}
    >
      <div className="u-fill-width">
        <Navigation
          right={
            <Button
              iconName={X24}
              inverse
              inline
              onClick={handleButtonClose}
              aria={{ 'aria-label': translate('a11y.close') }}
            />
          }
        />
        <div className="u-overflow-auto" aria-live="polite" aria-relevant="additions">
          <Auth componentLocation={ComponentLocation.AuthenticationModal} />
        </div>
      </div>
    </Dialog>
  )
}

const AuthModalWithProvider = () => (
  <AuthenticationProvider>
    <AuthenticationErrorBoundary componentLocation={ComponentLocation.AuthenticationModal}>
      <AuthModal />
    </AuthenticationErrorBoundary>
  </AuthenticationProvider>
)

export default AuthModalWithProvider
