'use client'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import useLocation from 'hooks/useLocation'
import useTranslate from 'hooks/useTranslate'

import { CookieConsentVersion } from 'constants/consent'
import {
  BUSINESS_TERMS_OF_SALE_URL,
  BUSINESS_TERMS_URL,
  COOKIE_POLICY_URL,
  OUR_PLATFORM_URL,
  PRIVACY_POLICY_URL,
  BUSINESS_TERMS_AND_CONDITIONS_URL,
  TERMS_URL,
} from 'constants/routes'

import { getOldHostRedirectUrl } from '_libs/utils/url'
import { toggleOneTrustInfoDisplay } from '_libs/utils/window'
import useTracking from 'hooks/useTracking'
import useConsent from 'hooks/useConsent'

import { clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import SectionLink from './SectionLink'

type Props = {
  isDigitalLawEnabled: boolean
  isBusinessInfoLinksEnabled: boolean
  onlineComplaintUrl: string | undefined
}

const PrivacySection = ({
  isDigitalLawEnabled,
  isBusinessInfoLinksEnabled,
  onlineComplaintUrl,
}: Props) => {
  const translate = useTranslate('footer.policy.items')
  const { track } = useTracking()

  const { cookieConsentVersion, isCookieConsentVersion } = useConsent()

  const isProTermsAndConditionsFSEnabled = useFeatureSwitch('pro_terms_and_conditions_enabled')

  const { host } = useLocation()

  const handleCookieSettingsClick = () => {
    toggleOneTrustInfoDisplay()
    track(
      clickEvent({
        target: ClickableElement.CookieSettings,
        screen: Screen.MainFooter,
      }),
    )
  }

  const cookieSettingsText =
    cookieConsentVersion === CookieConsentVersion.California
      ? translate('cookie_settings_us')
      : translate('cookie_settings')

  const renderBusinessInfoLinks = () => {
    if (!isBusinessInfoLinksEnabled) return null

    if (isProTermsAndConditionsFSEnabled) {
      return (
        <SectionLink
          text={translate('pro_terms_and_conditions')}
          url={BUSINESS_TERMS_AND_CONDITIONS_URL}
          testId="privacy-footer-pro-terms-and-conditions-link"
        />
      )
    }

    return (
      <>
        <SectionLink
          text={translate('pro_terms_of_sale')}
          url={BUSINESS_TERMS_OF_SALE_URL}
          testId="privacy-footer-pro-sale-term-link"
        />
        <SectionLink
          text={translate('pro_terms_of_use')}
          url={BUSINESS_TERMS_URL}
          testId="privacy-footer-pro-use-term-link"
        />
      </>
    )
  }

  return (
    <ul className="main-footer__privacy-section">
      <SectionLink
        text={translate('privacy_policy')}
        url={getOldHostRedirectUrl(host) + PRIVACY_POLICY_URL}
      />
      <SectionLink text={translate('cookie_policy')} url={COOKIE_POLICY_URL} />

      {isCookieConsentVersion && (
        <li className="main-footer__privacy-section-item">
          <button
            className="main-footer__privacy-section-link"
            type="button"
            onClick={handleCookieSettingsClick}
          >
            {cookieSettingsText}
          </button>
        </li>
      )}
      <SectionLink text={translate('terms_and_conditions')} url={TERMS_URL} />
      {isDigitalLawEnabled && (
        <SectionLink text={translate('our_platform')} url={OUR_PLATFORM_URL} />
      )}
      {renderBusinessInfoLinks()}
      {onlineComplaintUrl && (
        <SectionLink text={translate('online_complaints')} url={onlineComplaintUrl} />
      )}
    </ul>
  )
}

export default PrivacySection
